@media print {
    .Screensize {
        @apply tw-hidden;
    }

    .Navigation {
        .desktop-list {
            @apply tw-flex tw-gap-4 tw-list-none tw-p-0;

            a {
                @apply tw-ml-0 tw-uppercase;
            }
        }
    }

    .Footer {
        @apply tw-mt-20;

        .FooterContainer {
            @apply tw-grid tw-grid-flow-row tw-gap-8;

            .FooterContent {
                @apply tw-flex tw-flex-col tw-gap-8;

                .item-row {
                    @apply tw-grid-flow-col tw-w-full tw-basis-full tw-gap-20;

                    &-title {
                        @apply tw-p-0 tw-m-0 tw-font-sofia;
                    }

                    &-list,
                    ul {
                        @apply tw-list-none tw-list-inside tw-m-0 tw-p-0 tw-space-y-1;

                        svg {
                            @apply tw-hidden;
                        }
                    }

                    > div {
                        @apply tw-flex tw-flex-col tw-gap-4;
                    }
                }

                .FooterItemRow1 {
                    @apply tw-pb-8 tw-border-0 tw-border-b tw-border-solid;
                }

                .FooterItemRow3 {
                    @apply tw-flex tw-justify-between tw-pt-8 tw-border-0 tw-border-t tw-border-solid;

                    ul {
                        @apply tw-flex tw-gap-3 tw-items-baseline;
                    }
                }

                .ItemRowBlock {
                    @apply tw-flex tw-gap-20;
                }
            }

            .FooterBrand {
                @apply tw-order-first;

                img {
                    @apply tw-hidden;
                }

                ::after {
                    content: "";
                    @apply tw-block tw-text-25 tw-uppercase tw-h-11 tw-w-60;
                    background-image: url("/images/logo_hechenbichler_filled.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        .item-row {
            @apply tw-flex;
        }

        .acc-icon {
            @apply tw-w-4 tw-h-4;
        }

        ul {
            @apply tw-list-none;
        }
    }

    .Pagination {
        ul {
            @apply tw-flex tw-gap-3 tw-list-none;
        }
    }

    .CookieConsentModal {
        @apply tw-hidden;
    }
}
