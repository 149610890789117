@media print {
    html,
    body {
        @apply tw-text-17 tw-font-sofia-semi;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    a {
        @apply tw-no-underline tw-text-black;
    }

    a:visited {
        @apply tw-text-black;
    }
}
