.ContactFormWrapper {
    @apply tw-my-8;

    .ContactFormTitle {
        @apply tw-text-25 tw-font-bold;
    }

    .ContactFormSubtitle {
        @apply tw-text-25 tw-py-2;
    }
}

.ContactForm {
    .ContactFields {
        @apply tw-grid tw-grid-cols-2 tw-gap-4;

        input,
        select,
        textarea {
            @apply tw-w-full tw-py-4;
        }

        label,
        input[type="checkbox"] {
            @apply tw-hidden;
        }
    }

    .error-icon {
        @apply tw-hidden;
    }
}
