@media print {
    .ProductsHeader {
        @apply tw-hidden;
    }

    .ProductHeader {
        @apply tw-grid tw-grid-cols-2 tw-items-center tw-gap-8;

        .ProductImage {
            @apply tw-relative tw-h-96 tw-w-full tw-overflow-hidden;

            img {
                @apply tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-object-center tw-object-contain;
            }
        }

        .TagList,
        .WrapList {
            @apply tw-flex tw-gap-2 tw-flex-wrap tw-list-none tw-p-0;
        }

        .TagList li {
            @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
        }

        .WrapList a {
            @apply tw-underline;
        }
    }

    .RelatedProduct {
        .RelatedProductContainer {
            @apply tw-grid tw-grid-cols-2 tw-gap-7;

            a {
                @apply tw-uppercase;
            }
        }
    }

    .ProductTeaser,
    .related-products {
        .TagList {
            @apply tw-flex tw-gap-2 tw-flex-wrap;

            span {
                @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
            }
        }
    }

    .ProductTeaser {
        .ProductTeaserContainer {
            @apply tw-grid tw-grid-flow-col tw-gap-8;

            .ProductTeaserCard {
                @apply tw-flex-1;
            }
        }

        .ProductTeaserImageWrapper {
            @apply tw-relative tw-w-full tw-h-56 tw-overflow-hidden;

            img {
                @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-contain;
            }
        }
    }

    .related-products {
        @apply tw-grid tw-grid-cols-2 tw-gap-7;

        .ProductTeaserImageWrapper {
            img {
                @apply tw-block tw-mx-auto;
            }
        }

        .ProductTeaserCard {
            @apply tw-border-0 tw-border-r tw-border-solid last:tw-border-0;
        }
    }

    .ProductData {
        @apply tw-space-y-4;

        .ProductDataTitle {
            @apply tw-text-25 tw-font-bold;
        }

        .ProductDataRow {
            @apply tw-grid tw-grid-cols-3 tw-py-5 tw-gap-6 tw-border-0 tw-border-t tw-border-solid;

            .ProductDataLastChild {
                @apply tw-col-span-2;
            }
        }
    }
}
