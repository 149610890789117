@media print {
  html,
  body {
    @apply tw-text-17 tw-font-sofia-semi;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  a {
    @apply tw-no-underline tw-text-black;
  }
  a:visited {
    @apply tw-text-black;
  }
}
@media print {
  .Screensize {
    @apply tw-hidden;
  }
  .Navigation .desktop-list {
    @apply tw-flex tw-gap-4 tw-list-none tw-p-0;
  }
  .Navigation .desktop-list a {
    @apply tw-ml-0 tw-uppercase;
  }
  .Footer {
    @apply tw-mt-20;
  }
  .Footer .FooterContainer {
    @apply tw-grid tw-grid-flow-row tw-gap-8;
  }
  .Footer .FooterContainer .FooterContent {
    @apply tw-flex tw-flex-col tw-gap-8;
  }
  .Footer .FooterContainer .FooterContent .item-row {
    @apply tw-grid-flow-col tw-w-full tw-basis-full tw-gap-20;
  }
  .Footer .FooterContainer .FooterContent .item-row-title {
    @apply tw-p-0 tw-m-0 tw-font-sofia;
  }
  .Footer .FooterContainer .FooterContent .item-row-list,
  .Footer .FooterContainer .FooterContent .item-row ul {
    @apply tw-list-none tw-list-inside tw-m-0 tw-p-0 tw-space-y-1;
  }
  .Footer .FooterContainer .FooterContent .item-row-list svg,
  .Footer .FooterContainer .FooterContent .item-row ul svg {
    @apply tw-hidden;
  }
  .Footer .FooterContainer .FooterContent .item-row > div {
    @apply tw-flex tw-flex-col tw-gap-4;
  }
  .Footer .FooterContainer .FooterContent .FooterItemRow1 {
    @apply tw-pb-8 tw-border-0 tw-border-b tw-border-solid;
  }
  .Footer .FooterContainer .FooterContent .FooterItemRow3 {
    @apply tw-flex tw-justify-between tw-pt-8 tw-border-0 tw-border-t tw-border-solid;
  }
  .Footer .FooterContainer .FooterContent .FooterItemRow3 ul {
    @apply tw-flex tw-gap-3 tw-items-baseline;
  }
  .Footer .FooterContainer .FooterContent .ItemRowBlock {
    @apply tw-flex tw-gap-20;
  }
  .Footer .FooterContainer .FooterBrand {
    @apply tw-order-first;
  }
  .Footer .FooterContainer .FooterBrand img {
    @apply tw-hidden;
  }
  .Footer .FooterContainer .FooterBrand ::after {
    content: "";
    @apply tw-block tw-text-25 tw-uppercase tw-h-11 tw-w-60;
    background-image: url("/images/logo_hechenbichler_filled.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  .Footer .item-row {
    @apply tw-flex;
  }
  .Footer .acc-icon {
    @apply tw-w-4 tw-h-4;
  }
  .Footer ul {
    @apply tw-list-none;
  }
  .Pagination ul {
    @apply tw-flex tw-gap-3 tw-list-none;
  }
  .CookieConsentModal {
    @apply tw-hidden;
  }
}
@media print {
  .HomeHeader,
  .ProductTeaser,
  .ProductHeader,
  .RelatedProduct,
  .AB-ImageText,
  .AB-TextTitle,
  .AB-TextAnimation,
  .AB-BaseAreabrick,
  .Featured,
  .ListItem,
  .related-products {
    @apply tw-my-12;
  }
  .HomeHeader {
    @apply tw-my-12;
  }
  .HomeHeaderContainer {
    @apply tw-flex tw-gap-20 tw-items-center;
  }
  .HomeHeaderContainer a {
    @apply tw-block tw-mt-5;
  }
  .HomeHeaderContainer .banner {
    @apply tw-hidden;
  }
  .HomeHeaderContainer .teaser {
    @apply tw-text-30;
  }
  .HomeHeaderContainer .image-container {
    @apply tw-relative tw-mb-6 tw-w-56 tw-h-56 tw-overflow-hidden;
  }
  .HomeHeaderContainer .image-container img {
    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-contain;
  }
  .ImageText .content-container {
    @apply tw-flex tw-justify-between tw-items-center tw-gap-8;
  }
  .ImageText .content-container .title {
    @apply tw-block tw-mb-4 tw-text-20 tw-leading-tight;
  }
  .ImageText .content-container .hive-mask > .wrap {
    @apply tw-relative tw-w-56 tw-h-56 tw-shrink-0 tw-overflow-hidden;
  }
  .ImageText .content-container .hive-mask > .wrap img {
    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover tw-rounded-lg tw-overflow-hidden;
  }
  .ImageText .background {
    @apply tw-hidden;
  }
  .ImageTextColumn a,
  .RelatedProduct a {
    @apply tw-underline;
  }
  .ImageTextColumn .ImageWrapper,
  .RelatedProduct .ImageWrapper {
    @apply tw-rounded-lg tw-overflow-hidden tw-h-72 tw-w-72 tw-aspect-square;
  }
  .ImageTextColumn .ImageWrapper img,
  .RelatedProduct .ImageWrapper img {
    @apply tw-w-full tw-h-full tw-object-center tw-object-cover;
  }
  .AB-TextTitle h2 {
    @apply tw-font-normal;
  }
  .AB-TextAnimation {
    @apply tw-text-20 tw-leading-relaxed;
  }
  .EffectExperienceList .list-item {
    @apply tw-my-12 tw-border-0 tw-border-t tw-border-solid;
  }
  .EffectExperienceList .ListItem {
    @apply tw-flex tw-justify-between tw-items-center tw-gap-8;
  }
  .EffectExperienceList .ListItem a.ImageLink {
    @apply tw-block tw-relative tw-w-[270px] tw-h-[170px] tw-rounded-lg tw-overflow-hidden;
  }
  .EffectExperienceList .ListItem a.ImageLink img {
    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover;
  }
  .EffectExperienceList .ListItem .TagList {
    @apply tw-flex tw-gap-2 tw-flex-wrap tw-pb-8;
  }
  .EffectExperienceList .ListItem .TagList span {
    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
  }
  .EffectExperienceList .ListItemLink {
    @apply tw-underline tw-uppercase;
  }
  .EffectExperienceTeaser .EffectExperienceTeaserContainer,
  .EffectExperienceTeaser .FeaturedContainer,
  .Featured .EffectExperienceTeaserContainer,
  .Featured .FeaturedContainer {
    @apply tw-flex tw-justify-center tw-gap-20;
  }
  .EffectExperienceTeaser .EffectExperienceTeaserItem,
  .EffectExperienceTeaser .FeaturedItem,
  .Featured .EffectExperienceTeaserItem,
  .Featured .FeaturedItem {
    @apply tw-max-w-[288px];
  }
  .EffectExperienceTeaser .EffectExperienceTeaserItem .TagList,
  .EffectExperienceTeaser .FeaturedItem .TagList,
  .Featured .EffectExperienceTeaserItem .TagList,
  .Featured .FeaturedItem .TagList {
    @apply tw-flex tw-gap-2 tw-flex-wrap tw-pt-4 tw-pb-1;
  }
  .EffectExperienceTeaser .EffectExperienceTeaserItem .TagList span,
  .EffectExperienceTeaser .FeaturedItem .TagList span,
  .Featured .EffectExperienceTeaserItem .TagList span,
  .Featured .FeaturedItem .TagList span {
    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
  }
  .EffectExperienceTeaser .EffectExperienceTeaserLink,
  .Featured .EffectExperienceTeaserLink {
    @apply tw-underline tw-uppercase;
  }
  .EffectExperienceTeaser .image-container,
  .Featured .image-container {
    @apply tw-relative tw-max-w-[288px] tw-h-72 tw-aspect-square tw-rounded-lg tw-overflow-hidden;
  }
  .EffectExperienceTeaser .image-container img,
  .Featured .image-container img {
    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover;
  }
  .effectDetail .detail-header-img,
  .experienceDetail .detail-header-img {
    @apply tw-w-[453px] tw-h-[302px] tw-mt-8;
  }
  .effectDetail .detail-header-img img,
  .experienceDetail .detail-header-img img {
    @apply tw-w-full tw-h-full tw-object-center tw-object-cover;
  }
  .effectDetail .TagList,
  .experienceDetail .TagList {
    @apply tw-flex tw-gap-2 tw-p-0 tw-flex-wrap;
  }
  .effectDetail .TagList span,
  .experienceDetail .TagList span {
    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
  }
  .ApplicationsDownloads .ApplicationsTitle {
    @apply tw-text-25 tw-font-bold;
  }
  .ApplicationsDownloads .ApplicationsSubtitle {
    @apply tw-text-20 tw-font-semibold;
  }
  .ApplicationsDownloads .ApplicationsGrid {
    @apply tw-grid tw-grid-cols-3 tw-gap-6;
  }
  .ApplicationsDownloads .DownloadsGrid {
    @apply tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3;
  }
  .ApplicationsDownloads .DownloadsGrid a {
    @apply tw-underline;
  }
  .TagListFilter {
    @apply tw-flex tw-gap-2 tw-flex-wrap tw-pt-4 tw-pb-1;
  }
  .TagListFilter a {
    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
  }
  .Wysiwyg p:has(img) {
    @apply tw-relative tw-h-64 tw-w-80 tw-overflow-hidden;
  }
  .Wysiwyg img {
    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover;
  }
  .HechenbichlerOverlay {
    @apply tw-hidden;
  }
}
@media print {
  .ProductsHeader {
    @apply tw-hidden;
  }
  .ProductHeader {
    @apply tw-grid tw-grid-cols-2 tw-items-center tw-gap-8;
  }
  .ProductHeader .ProductImage {
    @apply tw-relative tw-h-96 tw-w-full tw-overflow-hidden;
  }
  .ProductHeader .ProductImage img {
    @apply tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-object-center tw-object-contain;
  }
  .ProductHeader .TagList,
  .ProductHeader .WrapList {
    @apply tw-flex tw-gap-2 tw-flex-wrap tw-list-none tw-p-0;
  }
  .ProductHeader .TagList li {
    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
  }
  .ProductHeader .WrapList a {
    @apply tw-underline;
  }
  .RelatedProduct .RelatedProductContainer {
    @apply tw-grid tw-grid-cols-2 tw-gap-7;
  }
  .RelatedProduct .RelatedProductContainer a {
    @apply tw-uppercase;
  }
  .ProductTeaser .TagList,
  .related-products .TagList {
    @apply tw-flex tw-gap-2 tw-flex-wrap;
  }
  .ProductTeaser .TagList span,
  .related-products .TagList span {
    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
  }
  .ProductTeaser .ProductTeaserContainer {
    @apply tw-grid tw-grid-flow-col tw-gap-8;
  }
  .ProductTeaser .ProductTeaserContainer .ProductTeaserCard {
    @apply tw-flex-1;
  }
  .ProductTeaser .ProductTeaserImageWrapper {
    @apply tw-relative tw-w-full tw-h-56 tw-overflow-hidden;
  }
  .ProductTeaser .ProductTeaserImageWrapper img {
    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-contain;
  }
  .related-products {
    @apply tw-grid tw-grid-cols-2 tw-gap-7;
  }
  .related-products .ProductTeaserImageWrapper img {
    @apply tw-block tw-mx-auto;
  }
  .related-products .ProductTeaserCard {
    @apply tw-border-0 tw-border-r tw-border-solid last:tw-border-0;
  }
  .ProductData {
    @apply tw-space-y-4;
  }
  .ProductData .ProductDataTitle {
    @apply tw-text-25 tw-font-bold;
  }
  .ProductData .ProductDataRow {
    @apply tw-grid tw-grid-cols-3 tw-py-5 tw-gap-6 tw-border-0 tw-border-t tw-border-solid;
  }
  .ProductData .ProductDataRow .ProductDataLastChild {
    @apply tw-col-span-2;
  }
}
.ContactFormWrapper {
  @apply tw-my-8;
}
.ContactFormWrapper .ContactFormTitle {
  @apply tw-text-25 tw-font-bold;
}
.ContactFormWrapper .ContactFormSubtitle {
  @apply tw-text-25 tw-py-2;
}

.ContactForm .ContactFields {
  @apply tw-grid tw-grid-cols-2 tw-gap-4;
}
.ContactForm .ContactFields input,
.ContactForm .ContactFields select,
.ContactForm .ContactFields textarea {
  @apply tw-w-full tw-py-4;
}
.ContactForm .ContactFields label,
.ContactForm .ContactFields input[type=checkbox] {
  @apply tw-hidden;
}
.ContactForm .error-icon {
  @apply tw-hidden;
}