@media print {
    .HomeHeader,
    .ProductTeaser,
    .ProductHeader,
    .RelatedProduct,
    .AB-ImageText,
    .AB-TextTitle,
    .AB-TextAnimation,
    .AB-BaseAreabrick,
    .Featured,
    .ListItem,
    .related-products {
        @apply tw-my-12;
    }

    .HomeHeader {
        @apply tw-my-12;

        &Container {
            @apply tw-flex tw-gap-20 tw-items-center;

            a {
                @apply tw-block tw-mt-5;
            }

            .banner {
                @apply tw-hidden;
            }

            .teaser {
                @apply tw-text-30;
            }

            .image-container {
                @apply tw-relative tw-mb-6 tw-w-56 tw-h-56 tw-overflow-hidden;

                img {
                    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-contain;
                }
            }
        }
    }

    .ImageText {
        .content-container {
            @apply tw-flex tw-justify-between tw-items-center tw-gap-8;

            .title {
                @apply tw-block tw-mb-4 tw-text-20 tw-leading-tight;
            }

            .hive-mask > .wrap {
                @apply tw-relative tw-w-56 tw-h-56 tw-shrink-0 tw-overflow-hidden;

                img {
                    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover tw-rounded-lg tw-overflow-hidden;
                }
            }
        }

        .background {
            @apply tw-hidden;
        }
    }

    .ImageTextColumn,
    .RelatedProduct {
        a {
            @apply tw-underline;
        }

        .ImageWrapper {
            @apply tw-rounded-lg tw-overflow-hidden tw-h-72 tw-w-72 tw-aspect-square;

            img {
                @apply tw-w-full tw-h-full tw-object-center tw-object-cover;
            }
        }
    }

    .AB-TextTitle {
        h2 {
            @apply tw-font-normal;
        }
    }

    .AB-TextAnimation {
        @apply tw-text-20 tw-leading-relaxed;
    }

    .EffectExperienceList {
        .list-item {
            @apply tw-my-12 tw-border-0 tw-border-t tw-border-solid;
        }

        .ListItem {
            @apply tw-flex tw-justify-between tw-items-center tw-gap-8;

            a.ImageLink {
                @apply tw-block tw-relative tw-w-[270px] tw-h-[170px] tw-rounded-lg tw-overflow-hidden;

                img {
                    @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover;
                }
            }

            .TagList {
                @apply tw-flex tw-gap-2 tw-flex-wrap tw-pb-8;

                span {
                    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
                }
            }
        }

        .ListItemLink {
            @apply tw-underline tw-uppercase;
        }
    }

    .EffectExperienceTeaser,
    .Featured {
        .EffectExperienceTeaserContainer,
        .FeaturedContainer {
            @apply tw-flex tw-justify-center tw-gap-20;
        }

        .EffectExperienceTeaserItem,
        .FeaturedItem {
            @apply tw-max-w-[288px];

            .TagList {
                @apply tw-flex tw-gap-2 tw-flex-wrap tw-pt-4 tw-pb-1;

                span {
                    @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
                }
            }
        }

        .EffectExperienceTeaserLink {
            @apply tw-underline tw-uppercase;
        }

        .image-container {
            @apply tw-relative tw-max-w-[288px] tw-h-72 tw-aspect-square tw-rounded-lg tw-overflow-hidden;

            img {
                @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover;
            }
        }
    }

    .effectDetail,
    .experienceDetail {
        .detail-header-img {
            @apply tw-w-[453px] tw-h-[302px] tw-mt-8;

            img {
                @apply tw-w-full tw-h-full tw-object-center tw-object-cover;
            }
        }

        .TagList {
            @apply tw-flex tw-gap-2 tw-p-0 tw-flex-wrap;

            span {
                @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
            }
        }
    }

    .ApplicationsDownloads {
        .ApplicationsTitle {
            @apply tw-text-25 tw-font-bold;
        }

        .ApplicationsSubtitle {
            @apply tw-text-20 tw-font-semibold;
        }

        .ApplicationsGrid {
            @apply tw-grid tw-grid-cols-3 tw-gap-6;
        }

        .DownloadsGrid {
            @apply tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3;

            a {
                @apply tw-underline;
            }
        }
    }

    .TagListFilter {
        @apply tw-flex tw-gap-2 tw-flex-wrap tw-pt-4 tw-pb-1;

        a {
            @apply tw-bg-gray-300 tw-py-1 tw-px-3 tw-rounded-full;
        }
    }

    .Wysiwyg {
        p:has(img) {
            @apply tw-relative tw-h-64 tw-w-80 tw-overflow-hidden;
        }

        img {
            @apply tw-absolute tw-w-full tw-h-full tw-object-center tw-object-cover;
        }
    }

    .HechenbichlerOverlay {
        @apply tw-hidden;
    }
}
